// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $blue;
	box-shadow: 0 0 8px rgba($black, 0.15);
	transition: 0.5s;

	.container-fluid {
		max-width: 1920px !important;

		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}
			}
		}
	}

	.header-top {
		background: $blue-light;
		padding: 5px 0;

		.container-fluid {
			.container-holder {
				>.column {
					@extend .navbar-expand;

					@include media-breakpoint-down(sm) {
						flex-wrap: wrap;
					}
				}
			}
		}
// news items
div.header-news{
	flex-grow: 1;

	@include media-breakpoint-down(sm) {
		flex-basis: 100%;
	}
	ul{
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: nowrap !important;
		align-items: center;

		
		&::before{
			content: url('/images/bell.svg');
			height: 24px;
		}
		li{
			font-weight: 500;
			font-size: 16px;
			color: $orange;
			pointer-events: none;
			z-index: -1;
			opacity: 0;
			transform: translateY(-100%);
			padding-left: .5rem;

			&.active{
				opacity: 1;
				z-index: 1;
				pointer-events: all;
				transform: translateY(0);
				transition: 1s ease;
			}
		}
		li:has(a){
			a{
				text-decoration: none;
				.list-item-suffix{
					text-decoration: 0.5px underline;
					text-underline-offset: 3px;
					margin-left: 0.35rem;
				}
				&:hover{
					color: $orange;
					.list-item-suffix{
						color: $white;
					}
				}
			}

		}
	}
}
		// menu
		.menu {
			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					+li {
						margin-left: 5px;

						@include media-breakpoint-up(sm) {
							margin-left: 15px;
						}
					}

					>.nav-link {
						color: $white;
						font-size: 16px;
						font-weight: 400;

						&:hover {
							color: $orange;
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}
			}
		}

		// site-switcher
		.site-switcher {
			margin-left: 15px;
		}
	}

	.header-bottom {
		padding: 15px 0;

		.container-fluid {
			.container-holder {
				>.column {
					@extend .navbar-expand-xl;
				}
			}
		}

		// menu-toggle
		.menu-toggle {
			.navbar-toggler {
				width: 40px;
				height: 40px;
				margin: 0 5px 0 -10px;
				padding: 0;
				border: none;
				color: $white;
				font-size: 24px;
				transition: color 0.5s;

				i {
					line-height: 40px;
				}

				&[aria-expanded="true"] {
					i {
						&::before {
							content: "\f00d";
						}
					}
				}

				&:hover {
					color: $orange;
				}
			}
		}

		// logo
		.logo {
			width: 185px;

			@include media-breakpoint-up(sm) {
				width: 200px;
			}

			@include media-breakpoint-up(xl) {
				width: 250px;
			}

			margin-right: auto;
			transition: width 0.5s;

			a {
				@extend .d-block;

				img {
					@extend .w-100;
				}
			}
		}

		// menu
		.menu {
			@include media-breakpoint-down(lg) {
				order: 5;

				.navbar-nav {
					margin: 15px 0;

					>.nav-item {
						>.nav-link {
							display: flex;
							align-items: center;
							color: $white;
							font-size: 18px;
							font-weight: 400;

							&:hover {
								color: $orange;
							}

							&.dropdown-toggle {
								&::after {
									content: "\f107";
									font-family: $font-awesome;
									border: none;
								}
							}
						}

						&.active {
							>.nav-link {
								color: $orange;
							}
						}
					}

					.nav-item {
						.dropdown-menu {
							position: static !important;
							transform: none !important;
							margin: 5px 0 0 25px;
							padding: 0;
							border: none;
							box-shadow: none;
							background: transparent;

							.nav-item {
								font-size: 18px;
								font-weight: 400;

								.nav-link {
									color: $white;

									&:hover {
										color: $orange;
									}
								}

								&:first-of-type {
									.nav-link {
										padding-top: 0;
									}
								}

								&.active {
									>.nav-link {
										color: $orange;
									}
								}
							}
						}
					}
				}
			}

			@include media-breakpoint-up(xl) {

				.navbar-nav {
					margin-left: auto;

					>.nav-item {
						margin: 0 10px;

						>.nav-link {
							color: $white;
							font-size: 20px;
							font-weight: 400;
							border-bottom: 1px solid transparent;

							&:hover {
								border-bottom-color: $white;
							}
						}

						&.active {
							>.nav-link {
								border-bottom-color: $white;
							}
						}
					}

					.nav-item {
						.dropdown-toggle {
							&::after {
								content: '\f107';
								font-family: $font-awesome;
								border: none;
								margin-left: 5px;
								font-weight: 400;
								vertical-align: middle;
							}
						}

						.dropdown-menu {
							margin-top: 0;
							padding: 10px;
							border: none;
							left: 0 !important;
							transform: none !important;
							box-shadow: $shadow;

							&::before,
							&::after {
								display: none;
							}

							.nav-item {
								font-size: 18px;
								font-weight: 400;
								line-height: 1.2;
								white-space: nowrap;

								.nav-link {
									color: $gray;

									&:hover {
										color: $orange;
									}
								}

								&.active {
									.nav-link {
										color: $orange;
									}
								}
							}
						}
					}
				}
			}
		}

		// demo-button
		div.demo-button {
			@include media-breakpoint-down(sm) {
				display: none;
			}

			a {
				@extend .btn;
				@extend .btn-primary;

				@include media-breakpoint-down(lg) {
					margin-left: 15px;
				}

				@media (min-width: 1200px) and (max-width: 1599px) {
					position: absolute;
					top: 85px;
					right: 15px;
					min-width: auto;
					padding: 10px;
				}

				@media (min-width: 1600px) {
					margin-left: 30px;
				}
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
