&.price-calculator-page {
	background: $gray-light;

	// calculator
	.calculator {
		max-width: 780px;

		h4 {
			font-weight: 300;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		// options
		.options {
			margin-bottom: 6vh;
			padding: 15px;
			background-color: $white;

			@include media-breakpoint-up(xl) {
				padding: 45px;
			}

			.heading {
				margin-bottom: 45px;
			}

			.items {
				.item {
					display: flex;
					align-items: center;
					margin-bottom: 15px;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
						align-items: flex-start;
					}

					.amount {
						display: flex;
						align-items: center;
						min-width: 220px;

						.minus,
						.plus {
							width: 36px;
							height: 36px;
							color: #c9c9c9;
							line-height: 36px;
							text-align: center;
							transition: color 0.5s;
							cursor: pointer;

							&:hover {
								color: $black;
							}
						}

						.plus {
							color: $orange;
						}

						.number {
							width: 110px;
							height: 36px;
							margin: 0 5px;
							border-color: $white;
							border-radius: 4px;
							box-shadow: 0 0 25px rgba($black, 0.15);
							color: #c9c9c9;
							text-align: center;

							&.above-zero {
								color: #243b68;
							}
						}
					}

					.name {
						color: $blue;
					}
				}
			}

			.monthly-costs {
				display: flex;
				flex-flow: row nowrap;
				font-size: 24px;
				margin-top: 45px;

				.text {
					flex-grow: 1;
					margin-right: 15px;
					font-weight: 400;
				}

				.price {
					font-weight: 700;
					white-space: nowrap;
				}
			}
		}

		// extras
		.extras {

			.heading {
				margin-bottom: 30px;
			}

			h4.open,h4.closed {
				margin-top: 30px;
			}

			h4.open::after {
				margin-left: 10px;
				content: "-";
				color: #df6508;

			}

			h4.closed::after {
				margin-left: 10px;
				content: "+";
				color: #df6508;
			}

			.items {
				.item {
					display: flex;
					margin-bottom: 15px;
					color: $blue;

					.custom-checkbox {
						flex-grow: 1;
						margin-right: 15px;

						.info {
							margin: 0 5px;
							transition: color 0.5s;
							cursor: pointer;

							&:hover {
								color: $black;
							}
						}
					}

					.price {
						font-weight: 500;
						white-space: nowrap;
					}
				}
			}

			.total-monthly-costs {
				display: flex;
				flex-flow: row nowrap;
				margin-top: 40px;
				padding-top: 25px;
				border-top: 1px solid #707070;
				color: $orange;
				font-size: 24px;

				@include media-breakpoint-up(xl) {
					font-size: 36px;
				}

				.text {
					flex-grow: 1;
					margin-right: 15px;
					font-weight: 400;
				}

				.price {
					font-weight: 700;
					white-space: nowrap;
				}
			}

			.price-info {
				position: relative;
				margin: 15px 0 30px 0;
				padding-left: 15px;
				font-weight: 100;

				.firstStar::before {
					content: "*";
					position: absolute;
					top: 0;
					left: 0;
					font-weight: 500;
				}

				.secondStar::before {
					content: "**";
					position: absolute;
					top: 25px;
					left: -10px;
					font-weight: 500;
				}

				strong {
					font-weight: 500;
				}
			}
		}
	}

	// footer-info
	.footer-info {
		.more-info {
			display: block !important;

			@include media-breakpoint-up(xl) {
				margin-left: 45px;
			}
		}

	}
}
