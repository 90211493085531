// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 8vh;
		padding-bottom: 8vh;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 60px 0;
	@extend .section-padding-x;

	@include media-breakpoint-up(xl) {
		font-size: 30px;
	}

	.title {
		h6 {
			margin-bottom: 20px;
		}
	}

	.info-nav {
		display: none;
	}

	.container-one-column {
		&.container-fluid {
			.container-holder {
				max-width: 1000px;

				.column {
					&.narrow {
						@include media-breakpoint-up(md) {
							max-width: 830px;
						}
					}
				}
			}
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.wide {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.narrow {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}

				&.sidebar {
					@extend .line-before;

					@include media-breakpoint-up(xl) {
						font-size: 20px;
					}

					.wysiwyg {
						margin-top: 15px;
					}
				}
			}
		}
	}

	// blog-category-filter
	.blog-category-filter {
		margin-bottom: 0;

		.list {
			.list-item {
				margin-right: 0;
				font-size: 20px;

				+li {
					&::before {
						content: "/";
						margin: 0 5px;
					}
				}

				.list-link {
					&:hover {
						color: $orange;
					}
				}
			}
		}
	}
}

&.contact-page {
	.lead-section {
		.faq-overview {
			max-width: 560px;
		}
	}
}

&.blog-post-overview,
&.blog-post-detail,
&.vacancy-vacancy-overview,
&.vacancy-vacancy-detail {
	.lead-section {
		padding-bottom: 60px !important;
	}
}

// intro-section
// =========================================================================
.intro-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}

	.container-two-columns {
		.column {
			&.one {
				&.spacing-left {
					@media (min-width: 1400px) {
						padding-left: 200px !important;
					}
				}
			}
		}
	}

	.wysiwyg {
		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}
	}

	.custom-list-extra {
		li {
			font-weight: 700;
		}
	}
}

&.service-detail {
	.intro-section {
		.container-two-columns {
			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(8);
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(4);
					}
				}
			}
		}
	}
}


// clients-section
// =========================================================================
.clients-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		margin-top: 5rem;

		&.slider {
			.card {
				border: none;
				border-radius: 0;

				.card-body {
					display: none !important;
				}
			}
		}

		/*
		&.grid {
			.grid-items {
				.item {
					@include make-col(4);

					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}

					@include media-breakpoint-up(md) {
						@include make-col(3);
					}

					@include media-breakpoint-up(lg) {
						flex: 0 0 20%;
						max-width: 20%;
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 0;

			.card-body {
				display: none !important;
			}
		}*/
	}
}

// featured-section
// =========================================================================
.featured-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// highlight
	.highlight {
		.card {
			@extend .default-card;
			margin-bottom: 40px;

			@include media-breakpoint-up(xl) {
				margin-bottom: 80px;
			}
		}

		&.last {
			.item {
				.card {
					margin-bottom: 0;
				}
			}
		}
	}

	// link
	div.link {
		display: flex;

		@include media-breakpoint-up(md) {
			margin: auto 0 auto auto;
		}

		a.link {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	.container-one-column {
		.container-holder {
			.column {
				&.narrow {
					@include media-breakpoint-up(md) {
						max-width: 820px;
					}
				}
			}
		}
	}
}

&.service-detail {
	.content-section {
		font-size: 24px;

		p:last-of-type {
			font-size: 20px;
		}
	}
}

// partners-section
// =========================================================================
.partners-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 30px;

					.card {
						align-items: center;
						border: none;

						.card-image {
							@include make-col(3);
						}

						.card-body {
							@include make-col(9);

							.card-caption {
								.card-title {
									margin-bottom: 0;
									@include font-size($h5-font-size);
								}
							}
						}
					}
				}
			}
		}
	}
}

// highlight-section
// =========================================================================
.highlight-section {
	margin-top: 8vh;
	margin-bottom: calc(8vh + 30px);
	@extend .section-padding-x;

	.container-two-columns {
		.container-holder {
			.column {
				@include media-breakpoint-only(md) {
					@include make-col(12);
				}

				&.col-narrow {
					@include media-breakpoint-up(xl) {
						@include make-col(5);
					}
				}

				&.col-wide {
					@include media-breakpoint-up(xl) {
						@include make-col(7);
					}
				}

				&.col-with-visual-bottom {
					align-self: flex-end;

					img {
						margin-bottom: calc(-8vh - 30px);
					}
				}
			}
		}
	}

	// faq-overview
	.faq-overview {
		margin-bottom: 30px;
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -40px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 40px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}

&.service-overview {
	.bundle-overview-section {
		margin-top: 0;

		.logo {
			position: absolute;
			left: 50%;
			margin-left: -150px;
			margin-top: -100px;
			//Hardcoded to prevent prosuco from uploading wrong format
			width: 300px;
			height: 200px;
			top: 50%;


		}
	}
}

&.features-overview {
	.bundle-overview-section {

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include make-col(12);

						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
					}

					&.two {
						@include make-col(12);

						@include media-breakpoint-up(lg) {
							@include make-col(8);
						}
					}
				}
			}
		}

		.features-overview {
			.categories {
				margin: 10px 0 0 0;
				padding: 0;
				list-style: none;

				li {
					margin-bottom: 30px;

					a {
						@extend .link-arrow;
						color: $gray;

						@include media-breakpoint-up(xl) {
							font-size: 24px;
						}

						&::after {
							margin-left: 15px;
						}

						&:hover,
						&:focus {
							color: $orange;
						}
					}
				}
			}

			.category-container {
				h2 {
					color: $black;
				}

				.item-container {
					&:not(:first-of-type) {
						margin-top: 6vh;
						padding: 15px;
						background: $gray-light;

						@include media-breakpoint-up(xl) {
							padding: 30px;
						}
					}
				}
			}
		}
	}
}


// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 8vh 0;
}

// latest-vacancy-section
// =========================================================================
.latest-vacancy-section {

	// collection
	.collection {
		margin-top: 8vh;

		.card {
			@extend .default-card;
			@extend .bg-dark;
		}
	}
}

// employee-bundle-overview-section
// =========================================================================
.employee-bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -45px;

				.item {
					@include make-col(6);
					margin-bottom: 45px;

					@include media-breakpoint-up(md) {
						@include make-col(4);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(3);
					}

					@include media-breakpoint-up(xl) {
						flex: 0 0 20%;
						max-width: 20%;
					}

					.card {
						@extend .default-card;
						padding-bottom: 15px;
						border-bottom: 1px solid $blue-light;

						.card-body {
							padding-top: 15px;

							.card-description {
								margin-top: 15px;

								p:last-of-type {
									margin-bottom: 0;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// functions-collection-section
// =========================================================================
.functions-collection-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 30px;

					.card {
						align-items: center;
						border: none;

						.card-image {
							@include make-col(3);

							@include media-breakpoint-up(sm) {
								@include make-col(2);
							}
						}

						.card-body {
							@include make-col(9);

							@include media-breakpoint-up(sm) {
								@include make-col(10);
							}

							.card-caption {
								.card-title {
									@include font-size($h5-font-size);
								}
							}
						}
					}

					&:nth-child(odd) {
						.card {
							background-color: $gray-light;

							@include media-breakpoint-up(xl) {
								padding: 15px 0;
							}
						}
					}
				}
			}
		}

		&.masonry {
			.card-columns {
				@include media-breakpoint-up(xl) {
					column-count: 4;
				}

				.item {
					.card {
						align-items: center;
						border: none;
						background-color: #e9ebef;

						.card-image {
							max-width: 50%;
							margin: 30px auto 0 auto;
						}

						.card-body {
							.card-caption {
								.card-title {
									@include font-size($h5-font-size);

									&:hover {
										color: currentColor;
									}
								}
							}
						}
					}

					&:nth-child(odd) {
						.card {
							background-color: #faeee6;
						}
					}
				}
			}
		}
	}
}

// vacancy-bundle-overview-section
// =========================================================================
.vacancy-bundle-overview-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		margin: 45px 0;

		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 45px;

					.card {
						@extend .default-card;

						.card-image {
							display: none;
						}

						.card-body {
							padding-top: 0;

							.card-caption {
								.card-title {
									@include font-size($h4-font-size);
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}
	}
}

// blog-bundle-overview-section
// =========================================================================
.blog-bundle-overview-section {
	margin-bottom: 8vh;
	@extend .section-padding-x;

	// .blog-filter
	.blog-filter,
	.blog-category-filter {
		display: none;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(xl) {
					margin: 0 -40px -40px -40px;
				}

				.item {
					@include media-breakpoint-up(xl) {
						margin-bottom: 40px;
						padding-left: 40px;
						padding-right: 40px;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;

						.card-body {
							.card-caption {
								.card-subtitle {
									font-size: 14px;
									font-weight: 400;
								}
							}
						}
					}

					&:not(:nth-child(-n+2)) {
						.card {
							.card-image {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// blog-detail-intro-section
// =========================================================================
.blog-detail-intro-section {
	@extend .section-padding-x;

	// image-collection
	.image-collection {
		.gallery-horizontal {
			.gallery-item {
				@include make-col(12);
			}

			.gallery-thumbs {
				display: none;
			}
		}
	}

	.info-nav {
		display: none;
	}

	.info {
		display: flex;
		flex-direction: column;

		.info-holder {
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 30px;
		}

		.subtitle {
			order: 0;

			h5 {
				margin-bottom: 0;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.tags {
			display: flex;
			flex-flow: row wrap;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 0;
				font-size: 14px;

				&::before {
					content: "/";
					margin: 0 5px;
				}

				a {
					&:hover {
						color: $orange;
					}
				}
			}
		}

		.wysiwyg {
			order: 1;
			position: relative;
			margin-bottom: 30px;

			@include media-breakpoint-up(xl) {
				font-size: 24px;
			}

			@extend .line-after;

			&::after {
				margin-top: 30px;
				background-color: $orange !important;
			}
		}

		.title {
			order: 2;
		}
	}
}

// vacancy-vacancy-detail
// =========================================================================
&.vacancy-vacancy-detail {

	// button-cv
	div.button-cv {
		position: fixed;
		top: 15px;
		right: 15px;

		a {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}

// other-services-section
// =========================================================================
.other-services-section {
	margin: 8vh 0;
	@extend .section-padding-x;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	// link
	div.link {
		display: flex;

		@include media-breakpoint-up(md) {
			margin: auto 0 auto auto;
		}

		a.link {
			@extend .btn;
			@extend .btn-primary;
		}
	}
}
