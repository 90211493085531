// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 1) 100%);
				opacity: 0.5;
			}

			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 30px;

				@include media-breakpoint-up(xl) {
					bottom: 90px;
				}

				.owl-container {
					.owl-caption {
						text-align: left;
						text-shadow: none;

						.owl-title {
							@include font-size($h3-font-size);
							font-weight: 300;
						}
					}
				}
			}
		}

		.owl-item {
			&.active {
				.owl-caption {
					animation: 1s .2s fadeInUp both;
				}
			}
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

&.service-detail {
	.eyecatcher {
		.owl-carousel {
			.owl-caption-holder {
				bottom: 50px;

				.owl-caption {
					.owl-title {
						margin-bottom: 0;
					}

					.owl-subtitle {
						display: none;
					}
				}
			}
		}
	}
}
