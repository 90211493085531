// https://animista.net/play/text/focus-in/text-focus-in
@keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}

	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

// https://animista.net/play/background/ken-burns/kenburns-top
@keyframes kenburns-top {
	0% {
		transform: scale(1) translateY(0);
		transform-origin: 50% 16%;
	}

	100% {
		transform: scale(1.25) translateY(-15px);
		transform-origin: top;
	}
}

// fadeInUp
.fadeInUp {
	animation-name: fadeInUp
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
