.timeline {
	max-width: 100%;
	@include media-breakpoint-down(lg) {
		padding: 0;
	}
	.timeline-title {
		display: none !important;
	}
	.timeline-entries {
		.timeline-entry {
			.timeline-entry-header {
				width: 100%;
				padding: 15px;
				border-radius: 4px;
				&:hover {
					background-color: rgba(gray, 0.1);
					transition: 0.35s ease;
				}
				.timeline-image {
					img {
						border: 0;
						border-radius: 50%;
						
					}
				}
				.timeline-title-block {
					
					.timeline-entry-title {
						margin-bottom: 0;
						font-size: 24px;
						@include media-breakpoint-down(lg) {
							font-size: 18px;
						}
					}
					h4 {
						font-weight: bold !important;
					}
					h5{
						font-weight: normal;
					}
				}
				.timeline-toggle {
					margin-left: auto;
					font-size: 32px;
				}
			
			}
			.timeline-details {
				transition: 0.5s ease;
				opacity: 0;
			}
			.timeline-details.active {
				display: block !important;
				transition: 0.35s ease;
				border-radius: 2px !important;
				box-shadow: 0 2px 5px rgba(#000, 0.2);
				background: $white;
				position: relative;
				opacity: 1;
				transition: 0.5s ease;
				@starting-style {
					display:block !important;
					opacity: 0;
					transition: 0.35s ease;
				}
				.timeline-toggle.close {
					position: absolute;
					top: 0;
					right: 1rem;
					font-size: 32px;
					cursor: pointer;
					font-size: 48px;
					font-weight: 200;
				}
				.timeline-details-container {
					display: flex;
					align-items: center;
					gap: 1.5rem;
					@include media-breakpoint-down(lg) {
						flex-flow: column;
						align-items: flex-start;
					}

					.timeline-details-image {
						img {
							width: 280px;
							height: unset;
							aspect-ratio: 1/1;
							border-radius: 50%;
							@include media-breakpoint-down(lg) {
								width: 90px;
							}
						}
					}
					.timeline-text {
						display: flex;
						flex-flow: row wrap;
						h4 {
							order: -1;
							font-weight: bold;
						}
						h5{
							font-weight: normal;
						}
						.timeline-entry-title {
							margin-bottom: 0;
							margin-right: 0.75rem;
							display: flex;
							justify-content: flex-start;
							font-size: 32px;
							@include media-breakpoint-down(lg) {
								font-size: 22px;
							}
						}
						.wysiwyg {
							flex-basis: 100%;
							font-size: 20px;
							@include media-breakpoint-down(lg) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}
