.modal {
	.modal-dialog {
		@include media-breakpoint-up(md) {
			max-width: 600px !important;
		}
	}
	.modal-content {
		border-radius: 6px;
		.modal-title {
			color: $orange;
			font-family: Outfit;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		.modal-body {
			p {
				color: #000;
				font-family: Outfit;
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}
		}
	}
}
