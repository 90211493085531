// custom-list
.custom-list {
	padding-left: 20px;

	li {
		margin: 10px 0;
		font-weight: 400;
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		margin-bottom: 20px;
		padding-left: 30px;

		@include media-breakpoint-up(xl) {
			padding-left: 40px;
		}

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}
