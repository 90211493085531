.timeline {
    .timeline-entries {
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
      .timeline-entry {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 100px;
          bottom: -50%;
          height: 60%;
          width: 1px;
          background: $black;
          z-index: -1;
          @include media-breakpoint-down(lg) {
            left: 70px;
            bottom: -40%;
          }
        }
        &:nth-last-child(1) {
          &::after {
            display: none;
          }
        }
        .timeline-entry-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 20px;
          transition: 0.35s ease;
          &.hidden {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 0.35s ease;
          }
          .timeline-image {
            width: 160px;
            display: block;
            aspect-ratio: 1/1;
            border-radius: 50%;
            @include media-breakpoint-down(lg) {
              max-width: 100px;
            }
            img,
            picture {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1/1;
              box-shadow: $shadow;
            }
          }
          .timeline-title-block {
            display: flex;
            flex-flow: row-reverse;
            align-items: baseline;
            gap: 0.5rem;
            margin-left: 1.5rem;
            @include media-breakpoint-down(lg) {
              flex-flow: column-reverse;
            }
            .timeline-entry-title {
              margin: 0;
            }
          }
          .timeline-toggle {
            margin-left: auto;
            background: none;
            border: none;
            cursor: pointer;
            width: 3rem;
            aspect-ratio: 1/1;
            color: $primary;
            padding: unset !important;
            min-width: unset !important;
          }
        }
        .timeline-details {
          display: block;
          width: 100%;
          background: $white;
          border-radius: 6px;
          box-shadow: $shadow;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.35s ease;
          position: relative;
          &.active {
            max-height: 200vh;
          }
          .timeline-details-container {
            display: flex;
            padding: 20px;
            gap: 1rem;
            @include media-breakpoint-down(lg) {
              flex-flow: column;
            }
            .timeline-details-image {
              width: 850px;
              img{
                object-fit: cover;
              }
              @include media-breakpoint-down(lg) {
                  width: 100%;
              }
            }
            .timeline-text {
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-items: baseline;
              .timeline-entry-title {
                margin: 0 0.35rem 0 0;
              }
              .wysiwyg {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
          .timeline-toggle {
            position: absolute;
            right: 2.5rem;
            top: 1.5rem;
            color: $primary;
            @include media-breakpoint-down(lg) {
              top: unset;
              bottom: 1rem;
            }
          }
        }
      }
    }
  }