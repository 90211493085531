// footer
.footer {

	// footer-contact
	.footer-contact {
		@include media-breakpoint-up(lg) {
			padding-bottom: 0 !important;
		}

		.container-two-columns {
			position: relative;

			.container-holder {
				>.column {
					@include media-breakpoint-only(md) {
						@include make-col(12);
					}
				}

				.one {
					.more-info {
						margin-top: 30px;

						@include media-breakpoint-down(md) {
							display: none;
						}

						h4 {
							color: $gray;
						}
					}
				}

				.two {
					align-self: flex-start;

					@include media-breakpoint-up(lg) {
						padding: 30px;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 15px;
							right: 0;
							bottom: 0;
							background: $white;
						}
					}

					.footer-text {
						h4 {
							font-size: 30px;
							font-weight: 300;
						}
					}

					.footer-text,
					.form {
						position: relative;
					}
				}
			}
		}
	}

	// footer-info
	.footer-info {
		background-color: $gray-light;

		.container-one-column {
			.container-holder {
				>.column {
					flex-direction: row;
					align-items: flex-end;
				}
			}
		}

		.visual-person {
			max-width: 700px;
		}

		.more-info {
			margin: 0 0 0 15px;
			font-size: 16px;

			@include media-breakpoint-up(sm) {
				margin: 0 0 30px 30px;
				font-size: 20px;
			}

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	// footer-partners
	.footer-partners {
		padding: 6vh 0;
		background-color: $white;

		// collection
		.collection {

			&.grid {
				.grid-items {
					justify-content: center;

					.item {
						@include make-col(4);

						@include media-breakpoint-up(sm) {
							@include make-col(4);
						}

						@include media-breakpoint-up(md) {
							@include make-col(3);
						}
					}
				}
			}

			.card {
				border: none;
				border-radius: 0;

				.card-body {
					display: none !important;
				}
			}
		}
	}

	// footer-banner
	.footer-banner {

		// owl-carousel
		.owl-carousel {

			.item {
				max-height: 880px;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba($black, 0.15);
				}

				picture {
					overflow: hidden;

					img {
						width: 100vw;
						object-fit: cover;
					}
				}

				.owl-caption-holder {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					@include media-breakpoint-up(md) {
						padding-left: 70px;
						padding-right: 70px;
					}

					.owl-container {
						position: relative;
						height: 100%;

						@media (min-width: 1400px) {
							max-width: 1660px;
							padding-left: 40px;
							padding-right: 40px;
						}

						.owl-caption {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							max-width: 1000px;
							height: 100%;
							padding: 8vh 0;
							text-shadow: none;
							text-align: left;

							.owl-title {
								margin-bottom: 0;
								@include font-size($h1-font-size);
								@extend .line-before;

								&::before {
									background-color: $white !important;
								}
							}

							.owl-subtitle {
								margin-bottom: 0;
							}

							.owl-btn {
								margin-top: auto;
							}
						}
					}
				}
			}

			.owl-nav,
			.owl-dots {
				display: none;
			}
		}
	}

	// footer-ending
	.footer-ending {
		@extend .section-padding-x;
		display: flex;
		flex-direction: column;
		padding-top: 8vh;
		padding-bottom: 40px;
		overflow: hidden;

		@include media-breakpoint-up(xl) {
			padding-top: 100px;
		}

		.container-one-column {
			.container-holder {
				max-width: 750px;
			}
		}

		// address
		.div-address {
			margin-top: auto;
			margin-bottom: 8vh;
			font-size: 18px;

			strong {
				font-weight: 700;
			}
		}

		// outro
		.div-outro {
			margin-bottom: 8vh;
		}

		// copyright
		.div-copyright {
			.container-holder {
				align-items: flex-end;

				>.column {
					@include make-col(6);
				}
			}

			.footer-text {
				ul {
					display: flex;
					flex-flow: row wrap;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
					}

					margin: 0;
					padding: 0;
					list-style: none;

					li {
						font-size: 14px;

						@include media-breakpoint-up(md) {
							+li {
								&::before {
									content: "/";
									margin: 0 5px;
								}
							}
						}

						a {
							color: $white;
							text-decoration: none !important;
						}
					}
				}
			}

			.footer-logolink {
				margin-left: auto;

				.list {
					justify-content: center;

					.list-item {
						+li {
							margin-left: 20px;
						}

						.link {
							display: block;
							font-size: 24px;
						}
					}
				}
			}
		}
	}
}
